
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

import LandingPageLayout from '@/components/layouts/LandingPageLayout';
import UnstyledLink from '@/components/links/UnstyledLink';
import Seo from '@/components/Seo';
import AppleStoreBadge from '@/components/ui/Icons/AppleStoreBadge';
import GooglePlayBadge from '@/components/ui/Icons/GooglePlayBadge';

import { USEFUL_LINKS } from '@/constants/UsefulLinks';
import { trackReferrer } from '@/utils/firebase';

export default function HomePage() {
  const router = useRouter();

  useEffect(() => {
    (async () => await router.push('/login'))();
    trackReferrer();
  }, [router]);
  const opts: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <LandingPageLayout hideSearchBox>
      <Seo />
      <div className='relative mx-auto mb-10 mt-6 h-[11rem] w-full max-w-[95%] sm:h-[30rem] sm:max-w-[80%] '>
        <YouTube
          videoId='I_YwCLPgXH4'
          opts={opts}
          className='mx-auto h-[11rem] w-[20rem] sm:h-[30rem] sm:w-[53rem] md:mx-0'
        />
      </div>
      <div className='flex w-auto flex-col items-center justify-center space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0'>
        <UnstyledLink href={USEFUL_LINKS.ANDROID_STORE}>
          <GooglePlayBadge />
        </UnstyledLink>
        <UnstyledLink href={USEFUL_LINKS.IOS_STORE}>
          <AppleStoreBadge />
        </UnstyledLink>
      </div>
    </LandingPageLayout>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  